import { ApplicationConfiguration } from './application';

declare global {
  interface Window {
    ga: (...arguments: any[]) => void;
  }
}

export default (configuration: ApplicationConfiguration): void => {
  if (DEPLOYED && configuration.trackingCode) {
    window.ga('create', configuration.trackingCode, 'auto');

    window.addEventListener('single-spa:routing-event', () => {
      window.ga('set', 'page', window.location.pathname);
      window.ga('send', 'pageview');
    });
  }
};
